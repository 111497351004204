<template>
  <b-spinner></b-spinner>
</template>

<script>
/**
 * @module Load
 * @category Views
 * @description A loading view
 */
export default {
  name: "Load",
  created() {

  }
}
</script>

<style scoped>

</style>
